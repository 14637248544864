<template>

  <div class="w1092">
    <div class="board">
      <ul class="boardw first">
        <li class="w150">{{$t('front.board.type')}}</li>
        <li class="">{{$t('front.board.title')}}</li>
      </ul>

      <template v-for="item in list" v-bind:key="item.boardIdx">
        <ul class="boardw">
          <li class="w150">{{$t('front.boardCategory.' + item.category)}}</li>
          <li class="textleft">
            <a href="#" @click.prevent="onClickRead(item.boardIdx)">
              {{item.title}}
              <span class="nsicon rdbg ml5">N</span>
            </a>
          </li>
        </ul>
      </template>
    </div>

    <div class="boardbtn"></div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList"
    />

    <board-filter @onSearch="onSearch"/>

  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'

export default {
  name: 'Notice',
  components: {
    Pagination,
    BoardFilter
  },
  data () {
    return {
      list: null,
      boardType: 'nt',
      searchParam: {
        searchOption: '',
        searchValue: ''
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList (page) {
      if (!page) {
        page = 1
      }
      const params = {
        boardType: this.boardType

      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      const result = await this.getBoardList(params, page)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'noticeRead', params: { boardIdx: id } })
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
